export const FETCH_START = "FETCH_START";
export const FETCH_SUCCESS = "FETCH_SUCCESS";
export const FETCH_SUCCESS_MSG = "FETCH_SUCCESS_MSG";
export const FETCH_ERROR = "FETCH_ERROR";
export const ERROR_BLANK = "ERROR_BLANK";
export const SHOW_LOADER = "SHOW_LOADER";
export const OPEN_KYC = "OPEN_KYC";
export const CLOSE_KYC = "CLOSE_KYC";
export const OPEN_CONSENT = "OPEN_CONSENT";
export const CLOSE_CONSENT = "CLOSE_CONSENT";
