export const _messages = {
    /** Util Begin */
    st: "st",
    nd: "nd",
    rd: "rd",
    th: "th",
    Sun: "Sun",
    Mon: "Mon",
    Tue: "Tue",
    Wed: "Wed",
    Thu: "Thu",
    Fri: "Fri",
    Sat: "Sat",
    Sunday: "Sunday",
    Monday: "Monday",
    Tuesday: "Tuesday",
    Wednesday: "Wednesday",
    Thursday: "Thursday",
    Friday: "Friday",
    Saturday: "Saturday",
    Jan: "Jan",
    Feb: "Feb",
    Mar: "Mar",
    Apr: "Apr",
    Jun: "Jun",
    Jul: "Jul",
    Aug: "Aug",
    Sep: "Sep",
    Oct: "Oct",
    Nov: "Nov",
    Dec: "Dec",
    January: "January",
    Febuary: "Febuary",
    March: "March",
    April: "April",
    May: "May",
    June: "June",
    July: "July",
    August: "August",
    September: "September",
    October: "October",
    November: "November",
    December: "December",
    /** Util End 404 Begin*/
    NETWORK_ERROR: "Network Error",
    API_NOT_FOUND: "API not found.",
    NOT_FOUND_SHORT_MESSAGE: "UH OH! You're lost.",
    NOT_FOUND_MESSAGE: "Not all who wander are lost, click the button below to go back to the homepage.",
    HOME: "Take me home",
    /** 404 End Login Begin */
    USER_NAME: "User Name",
    PASSWORD: "Password",
    LOGIN_MESSAGE: "Login to continue...",
    LOGIN: "Login",
    LOGIN_DETAILS_REQUIRED: "User Name and Password are required.",
    /** Login End Layout Begin */
    COPYWRITE: "Copyright © 20-22.",
    LOGOUT: "Logout",
    CONFIRM_TITLE: "Are you sure ?",
    YES: 'Yes',
    NO: 'No',
    /** Layout End Menu Begin */
    DASHBOARD: "Dashboard",
    CUSTOMERS: "Customers",
    PACKAGES: "Product Plans",
    MODULES: "Modules",
    PERIOD: "Period",
    SUBSCRIPTIONS: "Subscriptions",
    USER_ACCESS: "Menus & User Access",
    INVOICES: "Invoices",
    TRANSACTIONS: "Transactions",
    TYPE: "Type",
    EMAIL: "Email",
    MOBILE: 'Mobile',
    BUSSINESS: "Business",
    REPORTS: 'Reports',
    BENEFITS: 'Benefits',
    PAYOUT: 'Payout',
    PAYMENT: 'Payment',
    FREQUENCY: 'Frequency',
    DAYS: 'Days',
    FREE: 'Free',
    MONTH: 'Month',
    MONTHS: 'Months',
    TRIAL: 'Trial',
    /** Menu End Dashboard Begin */
    SNO: 'Sno.',
    ID: 'ID',
    LEAD: 'Lead',
    CLIENT_NAME_ID: 'Customer Name',
    SPOC: 'SPOC',
    DUE_DATE: 'Due Date',
    PLAN: 'Plan',
    INR: 'Inr',
    INTERNATIONAL: 'International',
    COUNT: 'Count',
    ACTION: 'Action',
    STATUS: 'Status',
    new: 'New Customer',
    about_to_expire: 'Customer about to expire',
    convertable: 'Convertable Customer',
    on_extension: 'Extended Customer',
    paid: 'Paid Customer',
    trial: 'Trial Customer',
    lost: 'Lost Customer',
    unconverted: 'Unconverted Customer',
    blocked: 'Blocked Customer',
    inactive: 'Inactive Customer',
    /** Dashboard End */
    CODE: 'Code',
    NAME: 'Name',
    PRICE: 'Price',
    PRODUCT: 'Product',
    PACKAGE: 'Package',
    DESCRIPTION: 'Description',
    PLANS: 'Plans',
    NEW: 'New',
    CREATE: 'Create new ?.',
    MODIFY: 'Modify ?.',
    SET_MENUS: 'Set menus.',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    DOWNLOAD: 'Download',
    SUBSCRIPTION: "Subscription",
    CUSTOMER: "Customer",
    DISCOUNT: 'discount',
    CYCLE: 'Cycle',
    BASE: 'Base',
    VIEW: 'View',
    USER: 'User',
    USERS: 'Users',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
    TERMINATION_DATE: 'Termination Date',
    INCORPORATION_DATE: 'Incorporation Date',
    BLOCK: 'Block ?.',
    BLOCK_ALERT: 'All active sessions will be terminated and account will be blocked.\n Customer Name : ?\n Customer Code : ?\n User Count : ?',
    VER: 'Ver.',
    SalaryAdvanceConvFee: "Set Salary Advance convenience fee",
    BASEDON: 'Based On',
    ADD_AMOUNT: "Add Amount",
    TRANSACTION_HISTORY: "Transaction History"
}