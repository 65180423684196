import React, { useEffect, Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';
// import { AutoLogin } from "../AppRedux/Actions/Auth";
// import { ThemeProvider } from "@material-ui/core/styles";
// import AppTheme from "./AppTheme";
import './global.css';
import UiLoader from "./loader";
const Login = lazy(() => import("./login"));
const MainApp = lazy(() => import("../Manage"));
const RestrictedRoute = ({ component: Component, token, ...rest }) => (
    <Route
        {...rest}
        render={
            props =>
                token ?
                    (<Component {...props} socket={rest.socket} />) :
                    (<Redirect to={{ pathname: "/login", state: { from: props.location } }} />)
        }
    />
);
const Components = (props) => {
    // const dispatch = useDispatch();
    // useEffect(() => {
    //     let url = new URL(window.location.href);
    //     let params = {
    //         token: url.searchParams.get('token') || false,
    //         redirect: ('/' + url.searchParams.get('redirect') || '')
    //     }
    //     if (params.token) {
    //         dispatch(AutoLogin(params.redirect, params.token, props.history))
    //     }
    // }, [dispatch, props.history]);
    const { match, location } = props;
    const { authUser, initURL, screenSetting, menus } = useSelector(state => state.auth);
    useEffect(() => {
        if (authUser) {
            if (location.pathname === "/" && !(initURL === "" || initURL === "/" || initURL === "/login")) {
                props.history.replace(initURL);
            } else if (location.pathname === "/login") {
                props.history.replace(initURL === "/login" ? "/" : initURL);
            }
            if (location.pathname === "/" && menus && menus.length > 0) {
                props.history.replace(menus[0].action_url);
            }
        }
    }, [authUser, initURL, location.pathname, props.history]);
    return (screenSetting ? null : <React.Fragment>
        <UiLoader />
        <Suspense fallback={<div />}>
            <Switch>
                <Route exact path="/login" component={Login} />
                <RestrictedRoute path={`${match.url}`} token={authUser} component={MainApp} />
            </Switch>
        </Suspense>
    </React.Fragment>);

}

export default Components;