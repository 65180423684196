import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore, { history } from './AppRedux/store';
import Components from './Components';
export const store = configureStore();
const App = () =>
  <Provider store={store}>
    <BrowserRouter history={history}>
      <Switch>
        <Route path="/" component={Components} />
      </Switch>
    </BrowserRouter>
  </Provider>;

export default App;