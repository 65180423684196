import {
  FETCH_START,
  FETCH_SUCCESS,
  FETCH_SUCCESS_MSG,
  FETCH_ERROR,
  ERROR_BLANK,
  SHOW_LOADER,
  OPEN_KYC,
  CLOSE_KYC,
  OPEN_CONSENT,
  CLOSE_CONSENT,
} from "../ActionTypes";

const INIT_STATE = {
  error: false,
  loading: false,
  success: false,
  time: new Date().getTime(),
  alert: "",
  kycopen: false,
  consentopen: false,
};

const defaultReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case FETCH_START: {
      return {
        ...state,
        error: false,
        success: false,
        alert: "",
        loading: true,
      };
    }
    case FETCH_SUCCESS: {
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
      };
    }
    case FETCH_SUCCESS_MSG: {
      return {
        ...state,
        error: false,
        alert: action.payload.replace(/\r\n/g, "<br/>"),
        time: new Date().getTime(),
        success: true,
        loading: false,
      };
    }
    case FETCH_ERROR: {
      downloadError(action.payload);
      return {
        ...state,
        error: true,
        success: false,
        alert: (action.payload || '').replace(/\r\n/g, "<br/>"),
        time: new Date().getTime(),
        loading: false,
      };
    }
    case ERROR_BLANK: {
      return {
        ...state,
        error: false,
        success: false,
        alert: "",
        time: new Date().getTime(),
        loading: false,
      };
    }
    case SHOW_LOADER: {
      return {
        ...state,
        error: false,
        success: false,
        loading: true,
      };
    }
    case OPEN_KYC: {
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
        kycopen: true,
      };
    }
    case CLOSE_KYC: {
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
        kycopen: false,
      };
    }
    case OPEN_CONSENT: {
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
        kycopen: false,
        consentopen: true,
      };
    }
    case CLOSE_CONSENT: {
      return {
        ...state,
        error: false,
        success: false,
        loading: false,
        kycopen: false,
        consentopen: false,
      };
    }
    default: {
      return state;
    }
  }
};

const downloadError = (errMessage = '') => {
  if (errMessage.indexOf("Row") !== -1) {
    exportErrData(errMessage);
  }
};

const exportErrData = (errStr = '') => {
  var linkElement = document.createElement("a");
  try {
    var uri = "data:application/csv;base64,";
    var base64 = function (s) {
      return window.btoa(unescape(encodeURIComponent(s)));
    };
    var csv_data = errStr.replace(/<br>/g, "\r\n");
    linkElement.setAttribute("href", uri + base64(csv_data));
    linkElement.setAttribute("download", "upload_err_log.csv");
    var clickEvent = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    linkElement.dispatchEvent(clickEvent);
  } catch (ex) {
    console.log(ex);
  }
};

export default defaultReducers;